import request from '@/utils/request'

export const calendar = (data) => {
  return request({
    url: '/reserve/calendar',
    method: 'post',
    data,
  })
}

export const address = (data) => {
  return request({
    url: '/reserve/address',
    method: 'post',
    data,
  })
}

export const carWash = (data) => {
  return request({
    url: '/reserve/carWash',
    method: 'post',
    data,
  })
}

export const getOccupyList = (data) => {
  return request({
    url: '/reserve/getOccupyList',
    method: 'post',
    data,
  })
}

export const getScanParam = (data) => {
  return request({
    url: '/reserve/getScanParam',
    method: 'post',
    data,
  })
}
