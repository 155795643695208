import request from '@/utils/request'

export const createOrder = (data) => {
  return request({
    url: '/pay/createOrder',
    method: 'post',
    data,
  })
}

export const queryOrder = (data) => {
  return request({
    url: '/pay/queryOrder',
    method: 'post',
    data,
  })
}

export const getWallet = (data) => {
  return request({
    url: '/wallet/get',
    method: 'post',
    data,
  })
}

export const walletRecord = (data) => {
  return request({
    url: '/wallet/record',
    method: 'post',
    data,
  })
}
