import request from '@/utils/request'

export const calendar = (data) => {
  return request({
    url: '/manage/swim/order/calendar',
    method: 'post',
    data,
  })
}
export const cancel = (data) => {
  return request({
    url: '/manage/swim/order/cancel',
    method: 'post',
    data,
  })
}
export const detail = (data) => {
  return request({
    url: '/manage/swim/order/detail',
    method: 'post',
    data,
  })
}
export const getList = (data) => {
  return request({
    url: '/manage/swim/order/getList',
    method: 'post',
    data,
  })
}
export const statistic = (data) => {
  return request({
    url: '/manage/swim/order/statistic',
    method: 'post',
    data,
  })
}
export const monthCount = (data) => {
  return request({
    url: '/swim/order/monthCount',
    method: 'post',
    data,
  })
}
export const reserve = (data) => {
  return request({
    url: '/swim/order/reserve',
    method: 'post',
    data,
  })
}
export const roomList = (data) => {
  return request({
    url: '/manage/swim/order/roomList',
    method: 'post',
    data,
  })
}
