import request from '@/utils/request'

export const calendar = (data) => {
  return request({
    url: '/manage/billiard/calendar',
    method: 'post',
    data,
  })
}

export const roomList = (data) => {
  return request({
    url: '/manage/billiard/roomList',
    method: 'post',
    data,
  })
}
export const statistic = (data) => {
  return request({
    url: '/manage/billiard/statistic',
    method: 'post',
    data,
  })
}
export const changeRoom = (data) => {
  return request({
    url: '/manage/billiard/changeRoom',
    method: 'post',
    data,
  })
}
export const getList = (data) => {
  return request({
    url: '/manage/billiard/getList',
    method: 'post',
    data,
  })
}
