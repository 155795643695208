import request from '@/utils/request'

export const getList = (data) => {
  return request({
    url: '/order/getList',
    method: 'post',
    data,
  })
}

export const detail = (data) => {
  return request({
    url: '/order/detail',
    method: 'post',
    data,
  })
}

export const cancel = (data) => {
  return request({
    url: '/order/cancel',
    method: 'post',
    data,
  })
}
