import Vue from 'vue'
import Vuex from 'vuex'
import * as login from '@/api/modules/login'
import * as utils from '@/utils'
// 引入插件
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const state = {
  userInfo: null,
  token: null,
  merOrderId: null, // 充值订单号
  siteId: null, // 站点id
  menuHeader: '',
}

const getters = {
  userInfo: (state) => {
    return state.userInfo
  },
  token: (state) => {
    return state.token
  },
  merOrderId: (state) => {
    return state.merOrderId
  },
  siteId: (state) => {
    return state.siteId
  },
  menuHeader: (state) => {
    return state.menuHeader
  },
}

const mutations = {
  SET_UER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_MER_ORDER_ID: (state, merOrderId) => {
    state.merOrderId = merOrderId
  },
  SET_SITE_ID: (state, siteId) => {
    state.siteId = siteId
  },
  SET_MENU_HEADER: (state, menuHeader) => {
    state.menuHeader = menuHeader
  },
}
const actions = {
  GET_USER_INFO: ({ commit }, userInfo) => {
    return new Promise((resolve, reject) => {
      //  本地模拟参数 1.管理员 2.洗车师傅 3.用户
      const userId = utils.getQueryValue('userId')
      const accessToken = utils.getQueryValue('accessToken')
      //如果userId或者accessToken等于空，则返回
      if (accessToken) {
        const data = {
          userId,
          accessToken,
        }
        login.doLogin(data).then((res) => {
          commit('SET_UER_INFO', res || {})
          commit('SET_TOKEN', res.token || {})
          resolve(res)
        })
      } else {
        reject()
      }
    })
  },
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  /* vuex数据持久化配置 */
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies  默认: localStorage
      storage: window.localStorage,
      // 存储的 key 的key值
      key: 'store',
      render(state) {
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state }
      },
    }),
  ],
})

export default store
