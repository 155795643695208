import request from '@/utils/request'

export const calendar = (data) => {
  return request({
    url: '/manage/calendar',
    method: 'post',
    data,
  })
}

export const statistic = (data) => {
  return request({
    url: '/manage/statistic',
    method: 'post',
    data,
  })
}

export const address = (data) => {
  return request({
    url: '/manage/address',
    method: 'post',
    data,
  })
}

export const getList = (data) => {
  return request({
    url: '/manage/getList',
    method: 'post',
    data,
  })
}

export const changeAddress = (data) => {
  return request({
    url: '/manage/changeAddress',
    method: 'post',
    data,
  })
}

export const complete = (data) => {
  return request({
    url: '/manage/complete',
    method: 'post',
    data,
  })
}

export const business = (data) => {
  return request({
    url: '/manage/business',
    method: 'post',
    data,
  })
}
