import request from '@/utils/request'

export const getBannerList = (data) => {
  return request({
    url: '/health/reserve/getBannerList',
    method: 'post',
    data,
  })
}
export const siteList = (data) => {
  return request({
    url: '/health/reserve/siteList',
    method: 'post',
    data,
  })
}

export const calendar = (data) => {
  return request({
    url: '/health/reserve/calendar',
    method: 'post',
    data,
  })
}

export const shiftList = (data) => {
  return request({
    url: '/health/reserve/shiftList',
    method: 'post',
    data,
  })
}
export const make = (data) => {
  return request({
    url: '/health/reserve/make',
    method: 'post',
    data,
  })
}
export const cancel = (data) => {
  return request({
    url: '/health/reserve/cancel',
    method: 'post',
    data,
  })
}

export const project = (data) => {
  return request({
    url: '/health/reserve/project',
    method: 'post',
    data,
  })
}

export const person = (data) => {
  return request({
    url: '/health/reserve/person',
    method: 'post',
    data,
  })
}

export const getTime = (data) => {
  return request({
    url: '/health/reserve/getTime',
    method: 'post',
    data,
  })
}
export const myList = (data) => {
  return request({
    url: '/health/reserve/myList',
    method: 'post',
    data,
  })
}

export const getOccupyList = (data) => {
  return request({
    url: '/health/reserve/getOccupyList',
    method: 'post',
    data,
  })
}

export const browseRecord = (data) => {
  return request({
    url: '/browse/record',
    method: 'post',
    data,
  })
}
