import request from '@/utils/request'

export const selectList = (data) => {
  return request({
    url: '/dp/selectList',
    method: 'get',
    data,
  })
}
export const selectSubList = (params) => {
  return request({
    url: '/dp/selectSubList',
    method: 'get',
    params,
  })
}
export const projectList = (data) => {
  return request({
    url: '/dp/projectList',
    method: 'post',
    data,
  })
}
export const projectDetail = (data) => {
  return request({
    url: '/dp/projectDetail',
    method: 'post',
    data,
  })
}
export const projectEvaList = (data) => {
  return request({
    url: '/dp/projectEvaList',
    method: 'post',
    data,
  })
}
export const comment = (data) => {
  return request({
    url: '/dp/comment',
    method: 'post',
    data,
  })
}
export const reply = (data) => {
  return request({
    url: '/dp/reply',
    method: 'post',
    data,
  })
}
export const my = (data) => {
  return request({
    url: '/dp/my',
    method: 'post',
    data,
  })
}
export const myEvaList = (data) => {
  return request({
    url: '/dp/myEvaList',
    method: 'post',
    data,
  })
}
export const myReplyList = (data) => {
  return request({
    url: '/dp/myReplyList',
    method: 'post',
    data,
  })
}
export const deleteEva = (data) => {
  return request({
    url: '/dp/deleteEva',
    method: 'post',
    data,
  })
}
