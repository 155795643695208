import Vue from 'vue'
import {
  Button,
  CellGroup,
  Cell,
  Icon,
  Swipe,
  SwipeItem,
  Divider,
  Tab,
  Tabs,
  List,
  PullRefresh,
  Form,
  Field,
  Popup,
  DatetimePicker,
  ActionSheet,
  Picker,
  NoticeBar,
  Tag,
  Calendar,
  Search,
  Empty,
  Uploader,
  Dialog,
  Image,
  DropdownMenu,
  DropdownItem,
  TreeSelect,
  Rate,
  Checkbox,
  RadioGroup,
  Radio,
} from 'vant'

Vue.use(Button)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Divider)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Form)
Vue.use(Field)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(ActionSheet)
Vue.use(Picker)
Vue.use(NoticeBar)
Vue.use(Tag)
Vue.use(Calendar)
Vue.use(Search)
Vue.use(Empty)
Vue.use(Uploader)
Vue.use(Dialog)
Vue.use(Image)
Vue.use(DropdownMenu).use(DropdownItem).use(TreeSelect).use(Rate).use(Checkbox).use(RadioGroup).use(Radio)
