import moment from 'moment'
require('moment/locale/zh-cn') // 引入中文本地化
import * as dicts from '@/utils/dicts'

export const getDay = (date) => {
  const day = moment(date).date()
  return day
}

export const getFullDate = (inputDateTime, format = 'YYYY-MM-DD dddd HH:mm') => {
  // 使用 Moment.js 解析日期时间
  const parsedDateTime = moment(inputDateTime)
  parsedDateTime.locale('zh-cn')
  // 格式化日期时间，包括星期几
  const formattedDateTime = parsedDateTime.format(format)
  return formattedDateTime
}

/**
 *
 * @param {字典值} value
 * @param {字典数组} dictName
 * @returns
 */
export const dictFormate = (value, dictName) => {
  if (value == null || value === '') return ''
  let dict = []
  if (typeof dictName == 'string') {
    dict = dicts[dictName] || []
  } else {
    dict = dictName || []
  }
  let codeName = ''
  for (let index = 0; index < dict.length; index++) {
    const element = dict[index]
    if (element.value == value) {
      codeName = element.name
      break
    }
  }
  return codeName
}

export const statusColor = (status, isStyle = false) => {
  let color = '#000'
  const colors = ['#0778FF', '#20BC7F', '#FC3F3F', '#FE8F02', '#0778FF', '#0778FF']
  color = status < colors.length ? colors[status] : color
  return isStyle ? color : 'color-' + color
}

export const formatAmount = (amount, decimalPlaces) => {
  if (isNaN(amount)) {
    return amount
  }
  // 四舍五入到指定小数位数
  const roundedAmount = Number(amount).toFixed(decimalPlaces)
  // 使用正则表达式将千分位分隔符插入
  const parts = roundedAmount.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}
