import request from '@/utils/request'

export const calendar = (data) => {
  return request({
    url: '/billiard/calendar',
    method: 'post',
    data,
  })
}

export const roomList = (data) => {
  return request({
    url: '/billiard/roomList',
    method: 'post',
    data,
  })
}

export const carWash = (data) => {
  return request({
    url: '/billiard/carWash',
    method: 'post',
    data,
  })
}

export const getOccupyList = (data) => {
  return request({
    url: '/billiard/getOccupyList',
    method: 'post',
    data,
  })
}

export const getScanParam = (data) => {
  return request({
    url: '/billiard/getScanParam',
    method: 'post',
    data,
  })
}

export const monthCount = (data) => {
  return request({
    url: '/billiard/monthCount',
    method: 'post',
    data,
  })
}
export const reserve = (data) => {
  return request({
    url: '/billiard/reserve',
    method: 'post',
    data,
  })
}
export const cancel = (data) => {
  return request({
    url: '/billiard/cancel',
    method: 'post',
    data,
  })
}

export const getList = (data) => {
  return request({
    url: '/billiard/getList',
    method: 'post',
    data,
  })
}
export const detail = (data) => {
  return request({
    url: '/billiard/detail',
    method: 'post',
    data,
  })
}
