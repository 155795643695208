import request from '@/utils/request'

export const getOrgPark = (data) => {
  return request({
    url: '/tb/car/getOrgPark',
    method: 'post',
    data,
  })
}

export const myCar = (data) => {
  return request({
    url: '/tb/car/my',
    method: 'post',
    data,
  })
}

export const detailCar = (data) => {
  return request({
    url: '/tb/car/detail',
    method: 'post',
    data,
  })
}

export const applyList = (data) => {
  return request({
    url: '/tb/car/applyList',
    method: 'post',
    data,
  })
}

export const apply = (data) => {
  return request({
    url: '/tb/car/apply',
    method: 'post',
    data,
  })
}

export const waitNum = (data) => {
  return request({
    url: '/mg/car/waitNum',
    method: 'post',
    data,
  })
}

export const waitList = (data) => {
  return request({
    url: '/mg/car/waitList',
    method: 'post',
    data,
  })
}

export const handleList = (data) => {
  return request({
    url: '/mg/car/handleList',
    method: 'post',
    data,
  })
}

export const mgDetailCar = (data) => {
  return request({
    url: '/mg/car/detail',
    method: 'post',
    data,
  })
}

export const cancelCar = (data) => {
  return request({
    url: '/mg/car/cancel',
    method: 'post',
    data,
  })
}

export const auditCar = (data) => {
  return request({
    url: '/mg/car/audit',
    method: 'post',
    data,
  })
}

export const noEnterList = (data) => {
  return request({
    url: '/wy/car/noEnterList',
    method: 'post',
    data,
  })
}

export const enterList = (data) => {
  return request({
    url: '/wy/car/enterList',
    method: 'post',
    data,
  })
}

export const wyDetail = (data) => {
  return request({
    url: '/wy/car/detail',
    method: 'post',
    data,
  })
}

export const enter = (data) => {
  return request({
    url: '/wy/car/enter',
    method: 'post',
    data,
  })
}

export const getDeptUser = (data) => {
  return request({
    url: '/tb/car/getDeptUser',
    method: 'post',
    data,
  })
}
