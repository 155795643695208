import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import './registerServiceWorker'
import store from './store'
import router from './router'
import '@/utils/vant'
import 'uno.css'
import '@/assets/styles/index.scss'
import api from './api'
import * as filters from '@/filters/index'

Vue.config.productionTip = false
Vue.use(api)
console.log(filters)
Object.keys(filters).forEach((filterName) => {
  Vue.filter(filterName, filters[filterName])
})

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
