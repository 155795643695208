import * as reserve from './modules/reserve'
import * as login from './modules/login'
import * as order from './modules/order'
import * as manage from './modules/manage'
import * as pay from './modules/pay'
import * as parking from './modules/parking'
import * as equip from './modules/equip'
import * as health from './modules/health'
import * as statistics from './modules/statistics'
import * as billiard from './modules/billiard'
import * as billiardManager from './modules/billiardManager'
import * as dianping from './modules/dianping'
import * as dianpingManager from './modules/dianpingManager'
import * as activity from './modules/activity'
import * as swim from './modules/swim'
import * as swimManager from './modules/swimManager'

export default {
  login,
  reserve,
  order,
  manage,
  pay,
  parking,
  equip,
  health,
  statistics,
  billiard,
  billiardManager,
  dianping,
  dianpingManager,
  activity,
  swim,
  swimManager,
}
