import request from '@/utils/request'

export const businessGetList = (data) => {
  return request({
    url: '/good/getBrandList',
    method: 'post',
    data,
  })
}
export const categoryGetList = (data) => {
  return request({
    url: '/good/getCateList',
    method: 'post',
    data,
  })
}

export const goodGetList = (data) => {
  return request({
    url: '/good/getList',
    method: 'post',
    data,
  })
}
export const goodDetail = (data) => {
  return request({
    url: '/good/detail',
    method: 'post',
    data,
  })
}

export const getBannerList = (data) => {
  return request({
    url: '/good/getBannerList',
    method: 'post',
    data,
  })
}
