import request from '@/utils/request'

export const siteList = (data) => {
  return request({
    url: '/health/statistics/siteList',
    method: 'post',
    data,
  })
}

export const shiftList = (data) => {
  return request({
    url: '/health/statistics/shiftList',
    method: 'post',
    data,
  })
}

export const record = (data) => {
  return request({
    url: '/health/statistics/record',
    method: 'post',
    data,
  })
}

export const analysis = (data) => {
  return request({
    url: '/health/statistics/analysis',
    method: 'post',
    data,
  })
}
