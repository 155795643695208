import request from '@/utils/request'

export const record = (data) => {
  return request({
    url: '/activity/child/reserve/record',
    method: 'post',
    data,
  })
}
export const detail = (data) => {
  return request({
    url: '/activity/child/reserve/detail',
    method: 'post',
    data,
  })
}
export const cancel = (data) => {
  return request({
    url: '/activity/child/reserve/cancel',
    method: 'post',
    data,
  })
}
export const make = (data) => {
  return request({
    url: '/activity/child/reserve/make',
    method: 'post',
    data,
  })
}
