<template>
  <div>
    <div :class="['flex items-center app-header', isWhite ? 'cus-bg-white' : '']">
      <div class="app-header__icon">
        <van-icon name="arrow-left" @click="backHandle" />
      </div>
      <div class="app-header__content">
        <div class="w-full">
          <!-- 菜单导航栏 -->
          <div v-if="typeof menus == 'object'" class="flex items-center justify-around">
            <div
              class="menu-item"
              :class="item.path == $route.path ? 'menu-item__active' : ''"
              v-for="(item, index) in menus"
              :key="index"
              @click="menuHandle(item)"
            >
              <span v-if="item.name">{{ item.name }}</span>
              <img v-else :src="item.path" class="menu-item__icon" alt="" />
            </div>
          </div>
          <!-- 子页面标题 -->
          <div v-else class="flex items-center justify-around">{{ menus }}</div>
        </div>
      </div>
      <div class="app-header__icon"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PoliceCarAppAppHeader',

  data() {
    return {
      custMenus: [
        {
          name: '洗车预约',
          path: '/customer/car-wash',
        },
        {
          name: '',
          path: require('@/assets/images/car-wash/header-icon.png'),
        },
        {
          name: '我的预约',
          path: '/mine-order',
        },
      ],
      billiardsMenus: [
        {
          name: '台球预约',
          path: '/customer/billiards',
        },
        {
          name: '',
          path: require('@/assets/images/billiards/header-icon.png'),
        },
        {
          name: '我的预约',
          path: '/customer/billiards/mine-order',
        },
      ],
      billiardsMangerMenus: [
        {
          name: '台球预约',
          path: '/manager/billiards',
        },
        {
          name: '',
          path: require('@/assets/images/billiards/header-icon.png'),
        },
        {
          name: '预约订单',
          path: '/manager/billiards/order',
        },
      ],
      managerMenus: [
        {
          name: '洗车预约',
          path: '/manager/car-wash',
        },
        {
          name: '洗车订单',
          path: '/manager/order',
        },
        {
          name: '业务管理',
          path: '/manager/business',
        },
      ],
      managerMenus2: [
        {
          name: '洗车预约',
          path: '/manager/car-wash',
        },
        {
          name: '',
          path: require('@/assets/images/car-wash/header-icon.png'),
        },
        {
          name: '洗车订单',
          path: '/manager/order',
        },
      ],
      payMenus: [
        {
          name: '线上充值',
          path: '/pay/index',
        },
        {
          name: '',
          path: require('@/assets/images/pay/header-icon.png'),
        },
        {
          name: '餐卡余额',
          path: '/pay/wallet',
        },
      ],
      parkingMenus: [
        {
          name: '停车审批',
          path: '/customer/parking/index',
        },
        {
          name: '',
          path: require('@/assets/images/parking/header-icon.png'),
        },
        {
          name: '我的申请',
          path: '/customer/parking/mine',
        },
      ],
      wyMenus: [
        {
          name: '待录入',
          path: '/manager/parking-wy/approval',
        },
        {
          name: '',
          path: require('@/assets/images/parking/header-icon.png'),
        },
        {
          name: '已录入',
          path: '/manager/parking-wy/approval2',
        },
      ],
      medicalMenus: [
        {
          name: '医疗预约',
          path: '/health/medical',
        },
        {
          name: '',
          path: require('@/assets/images/health/header-icon.png'),
        },
        {
          name: '我的预约',
          path: '/health/mine-order',
        },
      ],
      statisticsMenus: [
        {
          name: '预约记录',
          path: '/health/statistics',
        },
        {
          name: '',
          path: require('@/assets/images/health/header-icon.png'),
        },
        {
          name: '统计分析',
          path: '/health/analysis',
        },
      ],
      dianpingMenus: [
        {
          name: '大众点评',
          path: '/customer/dianping',
        },
        {
          name: '',
          path: require('@/assets/images/dianping/header-icon.png'),
        },
        {
          name: '我的点评',
          path: '/customer/dianping/mine-order',
        },
      ],
      swimMenus: [
        {
          name: '泅渡馆预约',
          path: '/customer/swim',
        },
        {
          name: '',
          path: require('@/assets/images/swim/header-icon.png'),
        },
        {
          name: '我的预约',
          path: '/customer/swim/mine-order',
        },
      ],
      swimMangerMenus: [
        {
          name: '泅渡馆预约',
          path: '/manager/swim',
        },
        {
          name: '',
          path: require('@/assets/images/swim/header-icon.png'),
        },
        {
          name: '预约订单',
          path: '/manager/swim/order',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'menuHeader']),
    menus() {
      const meta = this.$route.meta || {}
      console.log(meta)
      if (meta.menuType && this[meta.menuType]) {
        return this[meta.menuType]
      }
      return meta.title || this.menuHeader
    },
    isWhite() {
      const meta = this.$route.meta || {}
      return meta.isWhite || false
    },
  },
  mounted() {},
  methods: {
    menuHandle(item) {
      if (this.$route.path == item.path || !item.name) return
      this.$router.push(item.path)
    },
    backHandle() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-header {
  height: $headerHeight;
  background: url(~@/assets/images/car-wash/header-bg.png) top left no-repeat;
  background-size: cover;
  &__icon {
    width: 24px;
    text-align: center;
    font-size: 20px;
    color: #fff;
  }
  &__content {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
  }
}
.menu-item {
  padding-bottom: 4px;
  padding-top: 4px;
  &__icon {
    height: 42px;
  }
  &__active {
    border-bottom: 2px solid #fff;
  }
}

.cus-bg-white {
  background-image: none;
  background-color: #fff;
  .app-header__content {
    color: #383838;
  }
  .app-header__icon {
    color: #383838;
  }
}
</style>
