//状态 0.待洗车 1.已洗车 2.已爽约 3.已取消 4.休息 5.已预约

export const orderStatus = [
  { name: '待洗车', value: 0 },
  { name: '已洗车', value: 1 },
  { name: '已爽约', value: 2 },
  { name: '已取消', value: 3 },
  { name: '休息', value: 4 },
  { name: '已预约', value: 5 },
]

export const carType = [
  { name: '公车', value: 1 },
  { name: '私车', value: 2 },
]

export const carCate = [
  { name: '越野车', value: 1 },
  { name: '电车', value: 2 },
  { name: '轿车', value: 3 },
]

export const isOrNot = [
  { name: '否', value: 0 },
  { name: '是', value: 1 },
]

export const parkingStatus = [
  { name: '审批中', value: 0 },
  { name: '通过', value: 1 },
  { name: '已拒绝', value: 2 },
  { name: '已取消', value: 3 },
  { name: '已变更', value: 4 },
]

export const billiardStatus = [
  { name: '待赴约', value: 0 },
  { name: '已完结', value: 1 },
  { name: '已取消', value: 2 },
]

export const childActivityNum = [{ name: '1人', value: 1 }]

export const swimStatus = [
  { name: '待赴约', value: 0 },
  { name: '已完结', value: 1 },
  { name: '已取消', value: 2 },
]
